import React, { useEffect, useState } from 'react';
import SEO from '../shared/seo';
import { ReferenciaContenido } from '../interfaces/ReferenciaContenido';
import { getInsideInfo } from '../services/contentful';
import { getEntryDeliveryApi } from '../shared/services/CMS.js';
import LibraryComponents from '../components/templates/library-components/libraryComponents';
import GlobalContext from '../contexts/globalContext';
import Header from '../components/organisms/header/header';
import TopbarMenu from '../components/molecules/topbar-menu/topbarMenu';
import useLoginGigya from '../shared/hooks/useLoginGigya';
import useScreenSize from '../hooks/useScreenSize';
import Footer from '../components/molecules/footer/footer';
import HeadboardDientelandia from '../components/organisms/headboard-dientelandia/headboardDientelandia';

const TemplateSix = (props: any) => {
    const [pageContext, setPageContext] = useState<any>({});
    const [footerBg, setFooterBg] = useState<any>('');
    const gigyaContext = useLoginGigya();
    const screenSize = useScreenSize();

    const context = {
        gigyaContext: { ...gigyaContext },
    };

    useEffect(() => {
        const getPageContext = async () => {
            let dataCont: any = await getEntryDeliveryApi(
                props.pageContext.contentful_id,
            );
            dataCont.referenciaCabecera = await getInsideInfo(
                dataCont.referenciaCabecera,
            );
            dataCont.referenciaContenido = await getInsideInfo(
                dataCont.referenciaContenido,
            );
            dataCont.referenciaCabecera.migaDePan =
                props.pageContext.referenciaCabecera.migaDePan;
            setPageContext(dataCont);
            setFooterBg(
                dataCont.referenciaCabecera.imagenesArboles[1].file.url,
            );
        };
        getPageContext();
    }, []);

    const pageSlug = props.location.pathname;

    return (
        <GlobalContext.Provider value={context}>
            <SEO
                title={props.pageContext.metaTitulo || 'Dientelandia'}
                description={
                    props.pageContext.metaDescripcion &&
                    props.pageContext.metaDescripcion.json.content[0].content[0]
                        .value
                }
                noIndex={props.pageContext.noIndex}
                slug={pageSlug}
                fbId={process.env.FACEBOOK_APP_ID_HOME}
                image={
                    props.pageContext.referenciaCabecera.imagenHeader.file.url
                }
            />
            <div style={{ overflowX: 'hidden' }}>
                <Header />
                {screenSize > 968 && <TopbarMenu />}
                <HeadboardDientelandia
                    {...props.pageContext.referenciaCabecera}
                />
                {pageContext.referenciaContenido ? (
                    pageContext.referenciaContenido.map(
                        (value: ReferenciaContenido, index: number) => (
                            <LibraryComponents
                                key={index}
                                content={value}
                                categoryName={pageContext.nombreCategoria}
                            />
                        ),
                    )
                ) : (
                    <></>
                )}
                <Footer
                    isSuperFooter={false}
                    footerImg={footerBg}
                    footerWhite={false}
                    footerBlack={true}
                    footerDientelandia={true}
                />
            </div>
        </GlobalContext.Provider>
    );
};

export default TemplateSix;
